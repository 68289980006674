import { PersistedTextBlock } from "schemas";
import { Markdown } from "../typography";
import { textStyles, widthStyles } from "../styles";
import classNames from "classnames";

interface Props {
  block: PersistedTextBlock;
}
export function TextBlockCard({ block }: Props) {
  return (
    <Markdown fontFamily={block.fontFamily} className={classNames(textStyles.fontTheme, widthStyles.full)}>
      {block.text}
    </Markdown>
  );
}
