"use client";

import ReactSelect from "react-select";
import { useFormContext, useWatch } from "react-hook-form";
import { Colors, FontFamily, FontFamilyValues, fontFamilyCSS } from "schemas";
import { Label } from "./Label";
import { flexStyles, textStyles } from "../styles";
import { PropsWithClassName, PropsWithName, PropsWithOptionalLabel } from "../types";
import classNames from "classnames";

type AvailableValues = string | number;
type SelectOption<T> = {
  label: string;
  value: T | undefined | null;
};

interface Props<T> extends PropsWithName, PropsWithOptionalLabel, PropsWithClassName {
  options: SelectOption<T>[];
  placeholder?: string;
  raw?: boolean;
}

export function Select<T extends AvailableValues>({ className, options, label, name, raw, placeholder }: Props<T>) {
  const { setValue } = useFormContext();
  const value = useWatch({ name });
  const selectValue = options.find((option: SelectOption<T>) => option.value === value);

  const onChange = (value: SelectOption<T> | null) => {
    setValue(name, value?.value, { shouldDirty: true, shouldValidate: true });
  };

  const controlStyles = raw
    ? {
        background: "none",
        border: "none",
        boxShadow: "none",
        outline: "none",
        minHeight: "auto",
      }
    : {
        minHeight: "3.125rem",
        boxShadow: "none",
      };

  return (
    <div className={classNames(flexStyles.vert025, className)}>
      {label && <Label name={name}>{label}</Label>}
      <ReactSelect
        isSearchable={false}
        options={options}
        onChange={onChange}
        value={selectValue}
        placeholder={placeholder}
        classNames={{ option: () => textStyles.fontTheme }}
        styles={{
          control: (baseStyles) => ({
            ...baseStyles,
            ...controlStyles,
          }),
          valueContainer: (baseStyles) => ({
            ...baseStyles,
            padding: "0 0.875rem",
            fontSize: "0.875rem",
            lineHeight: "1.25rem",
          }),
          option: (baseStyles, ctx) => {
            const value = ctx.children;
            const fontStyles = FontFamilyValues.includes(value?.toString() as FontFamily)
              ? fontFamilyCSS(value as FontFamily)
              : {};
            return {
              ...baseStyles,
              ...fontStyles,
            };
          },
          indicatorSeparator: () => ({ display: "none" }),
          dropdownIndicator: (baseStyles, state) => ({
            ...baseStyles,
            transform: state.isFocused ? "rotate(180deg)" : "",
            margin: "-5px 0",
          }),
        }}
        theme={(theme) => ({
          ...theme,
          indicatorSeparator: () => ({ display: "none" }),
          colors: {
            ...theme.colors,
            primary: Colors.Secondary300,
            neutral20: Colors.Neutral400,
          },
        })}
      />
    </div>
  );
}
