import classNames from "classnames";
import { LinkButton } from "./LinkButton";
import { bgStyles, borderStyles, flexStyles, paddingStyles, positionStyles } from "../../styles";
import { useState } from "react";
import { LinkInput } from "./LinkInput";
import { useLexicalComposerContext } from "@lexical/react/LexicalComposerContext";
import { TOGGLE_LINK_COMMAND } from "@lexical/link";
import { ToolbarFontFamilySelect } from "./ToolbarFontFamilySelect";
import { ToolbarDivider } from "./ToolbarDivider";

interface Props {
  fontSelectable: boolean;
}
export function TopToolbarPlugin({ fontSelectable }: Props) {
  const [showLinkInput, setShowLinkInput] = useState<boolean>(false);
  const [linkValue, setLinkValue] = useState<string | null>(null);
  const [editor] = useLexicalComposerContext();

  const closeLinkInput = async () => {
    setShowLinkInput(false);
  };

  const setLink = async (url: string | null) => {
    editor.dispatchCommand(TOGGLE_LINK_COMMAND, url);
    closeLinkInput();
  };

  return (
    <div
      className={classNames(
        flexStyles.horiz050,
        flexStyles.alignCenter,
        bgStyles.editorBackground,
        borderStyles.bottomEditorBorder,
        positionStyles.absolute,
        positionStyles.top006,
        positionStyles.left006,
        positionStyles.right006,
        paddingStyles.py025,
        paddingStyles.px050,
      )}
    >
      {showLinkInput ? (
        <LinkInput value={linkValue} onSubmit={setLink} onClose={() => setShowLinkInput(false)} />
      ) : (
        <>
          {fontSelectable ? (
            <>
              <ToolbarFontFamilySelect />
              <ToolbarDivider />
            </>
          ) : undefined}
          <LinkButton setLinkValue={setLinkValue} setShowLinkInput={setShowLinkInput} />
        </>
      )}
    </div>
  );
}
