"use client";

import { InitialConfigType, LexicalComposer } from "@lexical/react/LexicalComposer";
import {
  $convertFromMarkdownString,
  BOLD_STAR,
  ITALIC_UNDERSCORE,
  ORDERED_LIST,
  UNORDERED_LIST,
  LINK,
} from "@lexical/markdown";
import { ListItemNode, ListNode } from "@lexical/list";
import { LinkNode } from "@lexical/link";
import { PropsWithClassName, PropsWithName, PropsWithOptionalFontFamily } from "../../types";
import { Editor } from "./Editor";
import { positionStyles } from "../../styles";

export const LITESITE_TRANSFORMERS = [UNORDERED_LIST, ORDERED_LIST, ITALIC_UNDERSCORE, BOLD_STAR, LINK];
const EDITOR_NODES = [ListNode, ListItemNode, LinkNode];

export type RichEditorType = "inline" | "form";
interface Props extends PropsWithClassName, PropsWithName, PropsWithOptionalFontFamily {
  type: RichEditorType;
  value: string;
  setValue: (value: string) => void;
  onSubmit?: () => Promise<void>;
  placeholder?: string;
  autoFocus: boolean;
  fontSelectable: boolean;
}
export function Wysiwyg({
  type,
  name,
  onSubmit,
  value,
  setValue,
  placeholder,
  autoFocus,
  fontFamily,
  fontSelectable,
}: Props) {
  const initialConfig: InitialConfigType = {
    theme: {
      text: {
        bold: "strong",
        italic: "em",
      },
    },
    namespace: `${name}-texarea`,
    nodes: EDITOR_NODES,
    onError: (error: Error) => {
      console.error(error);
    },
    editorState: () => $convertFromMarkdownString(value, LITESITE_TRANSFORMERS),
  };
  return (
    <div className={positionStyles.relative}>
      <LexicalComposer initialConfig={initialConfig}>
        <Editor
          fontFamily={fontFamily}
          type={type}
          autoFocus={autoFocus}
          onSubmit={onSubmit}
          placeholder={placeholder}
          setValue={setValue}
          fontSelectable={fontSelectable}
        />
      </LexicalComposer>
    </div>
  );
}
