import { EditorState } from "lexical";
import { RichTextPlugin } from "@lexical/react/LexicalRichTextPlugin";
import { OnChangePlugin } from "@lexical/react/LexicalOnChangePlugin";
import { ListPlugin } from "@lexical/react/LexicalListPlugin";
import { LinkPlugin } from "@lexical/react/LexicalLinkPlugin";
import { MarkdownShortcutPlugin } from "@lexical/react/LexicalMarkdownShortcutPlugin";
import { AutoFocusPlugin } from "@lexical/react/LexicalAutoFocusPlugin";
import { HistoryPlugin } from "@lexical/react/LexicalHistoryPlugin";
import { LexicalErrorBoundary } from "@lexical/react/LexicalErrorBoundary";
import { $convertToMarkdownString } from "@lexical/markdown";
import { ContentEditable } from "@lexical/react/LexicalContentEditable";
import classNames from "classnames";
import { LITESITE_TRANSFORMERS, RichEditorType } from "./Wysiwyg";
import styles from "./Editor.module.css";
import { SubmitPlugin } from "./SubmitPlugin";
import { heightStyles, paddingStyles, pointerStyles, positionStyles, textStyles, widthStyles } from "../../styles";
import { TopToolbarPlugin } from "./TopToolbarPlugin";
import { BottomToolbarPlugin } from "./BottomToolbarPlugin";
import { PropsWithOptionalFontFamily } from "../../types";
import { fontFamilyCSS } from "schemas";

interface Props extends PropsWithOptionalFontFamily {
  type: RichEditorType;
  setValue: (value: string) => void;
  onSubmit?: () => Promise<void>;
  placeholder?: string;
  autoFocus: boolean;
  fontSelectable: boolean;
}

export function Editor({ type, placeholder, onSubmit, setValue, autoFocus, fontFamily, fontSelectable }: Props) {
  const fontStyles = fontFamily ? fontFamilyCSS(fontFamily) : {};
  const onChange = (editorState: EditorState) => {
    editorState.read(() => {
      setValue($convertToMarkdownString(LITESITE_TRANSFORMERS));
    });
  };

  return (
    <>
      <RichTextPlugin
        contentEditable={
          <ContentEditable
            style={fontStyles}
            className={classNames(
              textStyles.size100,
              textStyles.fontTheme,
              paddingStyles.p0875,
              paddingStyles.pt350,
              paddingStyles.pb350,
              heightStyles.min1000,
              styles.inline,
              {
                [styles.editor]: type === "form",
              },
            )}
          />
        }
        placeholder={
          <div
            style={fontStyles}
            className={classNames(
              textStyles.size100,
              textStyles.fontPoppins,
              textStyles.lsColor,
              paddingStyles.p0875,
              pointerStyles.eventsNone,
              positionStyles.absolute,
              positionStyles.top275,
              positionStyles.left006,
              positionStyles.right006,
              widthStyles.full,
              {
                [textStyles.neutral300]: type === "form",
                [textStyles.editorBorder]: type !== "form",
              },
            )}
          >
            {placeholder}
          </div>
        }
        ErrorBoundary={LexicalErrorBoundary}
      />
      {autoFocus && <AutoFocusPlugin />}
      {onSubmit && <SubmitPlugin onlyMeta onSubmit={onSubmit} />}
      <MarkdownShortcutPlugin transformers={LITESITE_TRANSFORMERS} />
      <HistoryPlugin />
      <OnChangePlugin onChange={onChange} />
      <ListPlugin />
      <LinkPlugin />
      <TopToolbarPlugin fontSelectable={fontSelectable} />
      <BottomToolbarPlugin />
    </>
  );
}
