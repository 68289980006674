import classNames from "classnames";
import { bgStyles, borderStyles, flexStyles, paddingStyles, positionStyles } from "../../styles";
import { ToolbarButton } from "./ToolbarButton";
import { ToolbarDivider } from "./ToolbarDivider";

export function BottomToolbarPlugin() {
  return (
    <div
      className={classNames(
        flexStyles.horiz050,
        flexStyles.alignCenter,
        bgStyles.editorBackground,
        borderStyles.topEditorBorder,
        positionStyles.absolute,
        positionStyles.bottom006,
        positionStyles.left006,
        positionStyles.right006,
        paddingStyles.py025,
        paddingStyles.px050,
      )}
    >
      <ToolbarButton type="bold" />
      <ToolbarButton type="italic" />
      <ToolbarDivider />
      <ToolbarButton type="unordered-list" />
      <ToolbarButton type="ordered-list" />
    </div>
  );
}
